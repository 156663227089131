import * as React from "react";
import "./App.css";
import "@whereby.com/browser-sdk/embed"

// Replace this with your own Whereby room URL
const BASE_URL = "https://fysioscout.whereby.com";
const FALLBACK_URL = "https://fysioscout.com";

function App() {
  React.useEffect(() => {
    const { pathname, search } = window.location;

    if (pathname === "/" || !pathname) {
      window.location.href = FALLBACK_URL;
    }
  }, []);

  const { pathname, search } = window.location;
  const roomUrl = pathname && pathname !== "/"
    ? BASE_URL + (search ? `${pathname}${search}` : pathname)
    : null;

  if (!roomUrl) {
    return null; // or you could return a loading indicator
  }

  return <whereby-embed room={roomUrl} style={{
    position: 'fixed',
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: '100%',
    height: '100%',
    border: 'none',
    margin: "0",
    padding: "0",
    overflow: 'hidden',
    zIndex: "999999",
  }} />
}

export default App;
